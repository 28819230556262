import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import {
  Logo,
  AllSecureCallToAction,
  AnimatedLogo,
} from '../../innerComponents';

const Title = styled.h2`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements?.title)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements?.title)}
`;

export const DefaultDisplayContent = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-9">
          <Title>{t('site.modules.all-secure.heading')}</Title>
          <div className="row">
            <div className="col-xs-12 col-sm-3 col-md-4 col-lg-3">
              <Logo />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-8 col-lg-9">
              <AllSecureCallToAction
                url="https://www.informa.com/about-us/allsecure/"
                label={t('site.modules.all-secure.cta-1.label')}
              />
              <AllSecureCallToAction
                url="https://www.informa.com/globalassets/allsecure/20200629-informa-allsecure.pdf"
                label={t('site.modules.all-secure.cta-2.label')}
              />
            </div>
            <div className="col-xs-12 col-sm-3 hidden-md hidden-lg">
              <AnimatedLogo />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-3 visible-md visible-lg">
          <AnimatedLogo />
        </div>
      </div>
    </div>
  );
};
