import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import animatedLogo from '../../../../../../assets/images/allSecure/informa-all-secure-animation.gif';
import { ImageTag } from '../../../sharedComponents';

export const AnimatedLogo = () => {
  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles.elements?.animatedLogo;

  return (
    <ImageTag
      src={animatedLogo}
      displayStyles={dStyles}
      imgix={false}
      alt="Informa AllSecure"
    />
  );
};
