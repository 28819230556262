import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { renderStyledElementStyles } from '../../helpers';
import { SiteBuilderModule } from '../sharedComponents';
import { DefaultDisplayContent } from './displayStyles/DefaultDisplayContent';

export const ModuleWrapper = styled.div`
  position: relative;
  background-size: cover;
  background: #eee no-repeat center;

  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)}
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)}
`;

export default function AllSecureModule({ section }) {
  const themeConfig = useContext(ThemeContext);

  const { allSecureModule } = themeConfig.siteBuilderModules;
  const dStyles = allSecureModule.displayStyles.DEFAULT;
  const vStyles = allSecureModule.visualStyles.DEFAULT;

  return (
    <SiteBuilderModule section={section} moduleTheme={{ dStyles, vStyles }}>
      <ModuleWrapper data-testid="all-secure-module">
        <DefaultDisplayContent />
      </ModuleWrapper>
    </SiteBuilderModule>
  );
}

AllSecureModule.propTypes = {
  section: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};
