import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import logo from '../../../../../../assets/images/allSecure/informa-all-secure-logo.png';
import { ImageTag } from '../../../sharedComponents';

export const Logo = () => {
  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles.elements?.logo;

  return (
    <ImageTag
      src={logo}
      displayStyles={dStyles}
      imgix={false}
      alt="Informa AllSecure"
    />
  );
};
