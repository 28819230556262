import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import { Types } from '../../../../../shared/CallToAction/CallToAction';
import { CallToAction } from '../../../sharedComponents';

const CTAWrapper = styled(CallToAction)`
  display: block;
  width: auto;
  max-width: fit-content;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.callToAction,
    )}
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements?.callToAction,
    )}
  
  &:first-child {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements?.callToAction?.firstChild,
      )}
  }

  &:last-child {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements?.callToAction?.lastChild,
      )}
  }
`;

export const AllSecureCallToAction = ({ url, label }) => (
  <CTAWrapper to={url} target="_blank" type={Types.LINK}>
    {label}
  </CTAWrapper>
);

AllSecureCallToAction.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
};
